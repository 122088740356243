<template>
  <div class="container">
    <div class="container mt-5" v-if="showFirst">

      <div class="row justify-content-evenly">
        <div
          class="col-lg-2 col-sm-4 col-md-3 activType mt-3 d-flex justify-content-center align-items-center align-middle"
          style="background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/img/sektor/cher-met.png) no-repeat; border-radius: 15px; background-size: 100%; color: white;"
          data-type="5" data-text="Черная металлургия" @click="showProdType($event)">
          <p class="fw-bolder fs-5 mb-0">Черная металлургия</p>
        </div>
        <div class="col-lg-2 col-sm-4 col-md-3 activType mt-3 d-flex justify-content-center align-items-center "
          style="background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/img/sektor/pulpcolor.jpg) no-repeat; border-radius: 15px; background-size: 100%; color: white;"
          data-type="6" data-text="Цветная металлургия" @click="showProdType($event)">
          <p class="fw-bolder fs-5 mb-0 mx-3">Цветная металлургия</p>
        </div>
        <div class="col-lg-2 col-sm-4 col-md-3 activType mt-3 d-flex justify-content-center align-items-center"
          style="background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/img/sektor/proizv-mineralnyx-materialov.png) no-repeat; border-radius: 15px; background-size: 100%; color: white;"
          data-type="7" data-text="Производство минеральных материалов" @click="showProdType($event)">
          <p class="fw-bolder fs-5 mb-0">Производство минеральных материалов</p>
        </div>
        <!-- пока нет -->
        <!-- <div class="col-lg-2 col-sm-4 col-md-3 activType m-3 d-flex justify-content-center align-items-center"
          style="background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/img/sektor/mineral.jpg) no-repeat; border-radius: 15px; background-size: 100%; color: white;"
          data-type="8" data-text="Химическая промышленность" @click="showProdType($event)">
          <p class="fw-bolder fs-5 mb-0">Химическая промышленность</p>
        </div> -->
        <div class="col-lg-2 col-sm-4 col-md-3 activType mt-3 d-flex justify-content-center align-items-center"
          style="background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/img/sektor/cellyulozno-bumazhnoe-proizv.png) no-repeat; border-radius: 15px; background-size: 100%; color: white;"
          data-type="13" data-text="Целлюлозно-бумажное производство" @click="showProdType($event)">
          <p class="fw-bolder fs-5 mb-0">Целлюлозно-бумажное производство</p>
        </div>
        <div class="col-lg-2 col-sm-4 col-md-3 activType mt-3 d-flex justify-content-center align-items-center"
          style="background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(/img/sektor/mineral.jpg) no-repeat; border-radius: 15px; background-size: 100%; color: white;"
          data-type="14" data-text="Производство минеральных удобрений" @click="showProdType($event)">
          <p class="fw-bolder fs-5 mb-0">Производство минеральных удобрений</p>
        </div>
      </div>
    </div>
    <div class="container" v-if="showSecond">
      <div class="row justify-content-evenly h-100 w-100">
        <template v-if="actType == 5">
          <div
            class="col-lg-3 col-sm-6 col-md-4 prodType my-5 d-flex justify-content-center align-items-center align-middle"
            v-for="(data, index) in bData" :key="index"
            :style="{ background: data.imgSrc + ' no-repeat', backgroundSize: '100%', color: 'white' }"
            :data-type="25 + index" :data-text="data.label" @click="showBlackMet($event)">
            <p class="fw-bolder fs-5 mb-0">{{ data.label }}</p>
          </div>
        </template>
        <template v-if="actType == 6">
          <div
            class="col-lg-3 col-sm-6 col-md-4 prodTypeC my-5 d-flex justify-content-center align-items-center align-middle"
            v-for="(data, index) in colorbData" :key="index"
            style="background: url(/img/metal/black1.jpg) no-repeat; background-size: 100%; color: white;"
            :data-type="36 + index" :data-text="data.label" @click="showColorkMet($event)">
            <p class="mt-2">{{ data.label }}</p>
          </div>
        </template>
        <!-- <div class="col-2 prodType m-5"
          style="background: url(/img/metal/colour1.jpg) no-repeat; background-size: 100%; color: white;" data-type="36"
          data-text="Производство свинца первичного" @click="showProd($event)" v-if="actType == 6">
          <p class="mt-5">Производство свинца первичного</p>
        </div>
        <div class="col-2 prodType m-5"
          style="background: url(/img/metal/colour2.jpg) no-repeat; background-size: 100%; color: white;" data-type="37"
          data-text="Производство цинка" @click="showProd($event)" v-if="actType == 6">
          <p class="mt-5">Производство цинка</p>
        </div>
        <div class="col-2 prodType m-5"
          style="background: url(/img/metal/colour3.jpg) no-repeat; background-size: 100%; color: white;" data-type="38"
          data-text="Производство алюминия (технология CWPB/PFPB)" @click="showProd($event)" v-if="actType == 6">
          <p class="mt-5">Производство алюминия (технология CWPB/PFPB)</p>
        </div>
        <div class="col-2 prodType m-5"
          style="background: url(/img/metal/colour4.jpg) no-repeat; background-size: 100%; color: white;" data-type="39"
          data-text="Производство алюминия (технология SWPB)" @click="showProd($event)" v-if="actType == 6">
          <p class="mt-5">Производство алюминия (технология SWPB)</p>
        </div>
        <div class="col-2 prodType m-5"
          style="background: url(/img/metal/colour5.jpg) no-repeat; background-size: 100%; color: white;" data-type="40"
          data-text="Производство алюминия (технология HSS)" @click="showProd($event)" v-if="actType == 6">
          <p class="mt-5">Производство алюминия (технология HSS)</p>
        </div>
        <div class="col-2 prodType m-5"
          style="background: url(/img/metal/colour6.jpg) no-repeat; background-size: 100%; color: white;" data-type="41"
          data-text="Производство алюминия (технология VSS)" @click="showProd($event)" v-if="actType == 6">
          <p class="mt-5">Производство алюминия (технология VSS)</p>
        </div> -->
        <div class="col-lg-2 col-sm-4 col-md-3 prodType minerals mt-5 d-flex justify-content-center align-items-center"
          style="background: url(/img/mineral/proizvodstvo-cementa.png) no-repeat; background-size: 100%; color: white;"
          data-type="42" data-text="Производство цемента" @click="showProd($event)" v-if="actType == 7">
          <p class="fw-bolder fs-5">Производство цемента</p>
        </div>
        <div class="col-lg-2 col-sm-4 col-md-3 prodType minerals mt-5 d-flex justify-content-center align-items-center"
          style="background: url(/img/mineral/proizvodstvo-cementa.png) no-repeat; background-size: 100%; color: white;"
          data-type="61" data-text="Производство кирпича" @click="showProd($event)" v-if="actType == 7">
          <p class="fw-bolder fs-5">Производство кирпича</p>
        </div>
        <!-- <div class="col-2 prodType minerals m-5"
          style="background: url(/img/mineral/mat2.jpg) no-repeat; background-size: 100%; color: white;" data-type="43"
          data-text="Производство извести" @click="showProd($event)" v-if="actType == 7">
          <p class="mt-5">Производство извести</p>
        </div> -->
        <div class="col-lg-2 col-sm-4 col-md-3 prodType minerals mt-5 d-flex justify-content-center align-items-center"
          style="background: url(/img/mineral/proizvodstvo-vsex-vidov-stekla.png) no-repeat; background-size: 100%; color: white;"
          data-type="44" data-text="Производство всех видов стекла" @click="showProd($event)" v-if="actType == 7">
          <p class="fw-bolder fs-5">Производство всех видов стекла</p>
        </div>
        <!-- <div class="col-2 prodType minerals m-5"
          style="background: url(/img/mineral/mat4.jpg) no-repeat; background-size: 100%; color: white;" data-type="45"
          data-text="Производство керамических изделий" @click="showProd($event)" v-if="actType == 7">
          <p class="mt-5">Производство керамических изделий</p>
        </div> -->
        <!-- <div class="col-2 prodType m-5"
          style="background: url(/img/him/mat1.jpg) no-repeat; background-size: 100%; color: white;" data-type="46"
          data-text="Производство аммиака" @click="showProd($event)" v-if="actType == 8">
          <p class="mt-5">Производство аммиака</p>
        </div> -->
        <!-- <div class="col-2 prodType m-5"
          style="background: url(/img/him/mat2.jpg) no-repeat; background-size: 100%; color: white;" data-type="47"
          data-text="Производство азотной кислоты" @click="showProd($event)" v-if="actType == 8">
          <p class="mt-5">Производство азотной кислоты</p>
        </div> -->
        <div class="col-2 prodType mt-5"
          style="background: url(/img/him/mat3.jpg) no-repeat; background-size: 100%; color: white;" data-type="48"
          data-text="Производство капролактама" @click="showProd($event)" v-if="actType == 8">
          <p class="mt-5">Производство капролактама</p>
        </div>
        <div class="col-2 prodType mt-5"
          style="background: url(/img/him/mat4.jpg) no-repeat; background-size: 100%; color: white;" data-type="49"
          data-text="Производство глиоксаля" @click="showProd($event)" v-if="actType == 8">
          <p class="mt-5">Производство глиоксаля</p>
        </div>
        <div class="col-2 prodType mt-5"
          style="background: url(/img/him/mat5.jpg) no-repeat; background-size: 100%; color: white;" data-type="50"
          data-text="Производство карбида кальция" @click="showProd($event)" v-if="actType == 8">
          <p class="mt-5">Производство карбида кальция</p>
        </div>
        <div class="col-2 prodType mt-5"
          style="background: url(/img/him/mat6.jpg) no-repeat; background-size: 100%; color: white;" data-type="51"
          data-text="Производство карбида кремния" @click="showProd($event)" v-if="actType == 8">
          <p class="mt-5">Производство карбида кремния</p>
        </div>
        <div class="col-2 prodType mt-5"
          style="background: url(/img/him/mat7.jpg) no-repeat; background-size: 100%; color: white;" data-type="52"
          data-text="Производство диоксида титана" @click="showProd($event)" v-if="actType == 8">
          <p class="mt-5">Производство диоксида титана</p>
        </div>
        <!-- <div class="col-2 prodType minerals m-5"
          style="background: url(/img/paper/mat1.jpg) no-repeat; background-size: 100%; color: white;" data-type="54"
          data-text="производство сульфатной целлюлозы" @click="showProd($event)" v-if="(actType == 13)">
          <p class="mt-5">производство сульфатной целлюлозы</p>
        </div> -->
        <!-- <div class="col-2 prodType minerals m-5"
          style="background: url(/img/paper/mat2.jpg) no-repeat; background-size: 100%; color: white;" data-type="55"
          data-text="производство сульфитной целлюлозы" @click="showProd($event)" v-if="(actType == 13)">
          <p class="mt-5">производство сульфитной целлюлозы</p>
        </div> -->
        <!-- <div class="col-2 prodType minerals m-5"
          style="background: url(/img/paper/mat1.jpg) no-repeat; background-size: 100%; color: white;" data-type="56"
          data-text="производство механических видов древесной массы" @click="showProd($event)" v-if="(actType == 13)">
          <p class="mt-5">производство механических видов древесной массы</p>
        </div> -->
        <div class="col-lg-2 col-sm-4 col-md-3 prodType minerals mt-5 d-flex justify-content-center align-items-center"
          style="background: url(/img/paper/mat3.jpg) no-repeat; background-size: 100%; color: white;" data-type="57"
          data-text="производство бумаги" @click="showProd($event)" v-if="(actType == 13)">
          <p class="fw-bolder fs-5">производство бумаги</p>
        </div>
        <div class="col-lg-2 col-sm-4 col-md-3 prodType minerals mt-5 d-flex justify-content-center align-items-center"
          style="background: url(/img/paper/mat4.jpg) no-repeat; background-size: 100%; color: white;" data-type="58"
          data-text="производство картона" @click="showProd($event)" v-if="(actType == 13)">
          <p class="fw-bolder fs-5">производство картона</p>
        </div>
        <!-- <div class="col-2 prodType minerals m-5"
          style="background: url(/img/paper/mat1.jpg) no-repeat; background-size: 100%; color: white;" data-type="59"
          data-text="производственные процессы вспомогательных производств" @click="showProd($event)"
          v-if="(actType == 13)">
          <p class="mt-5">производственные процессы вспомогательных производств</p>
        </div> -->
        <div class="col-lg-2 col-sm-4 col-md-3 prodType minerals mt-5 d-flex justify-content-center align-items-center"
          style="background: url(/img/paper/mat3.jpg) no-repeat; background-size: 100%; color: white;" data-type="59"
          data-text="производство аммиака" @click="showProd($event)" v-if="(actType == 14)">
          <p class="fw-bolder fs-5">производство аммиака</p>
        </div>
        <div class="col-lg-2 col-sm-4 col-md-3 prodType minerals mt-5 d-flex justify-content-center align-items-center"
          style="background: url(/img/paper/mat3.jpg) no-repeat; background-size: 100%; color: white;" data-type="60"
          data-text="производство азотной кислоты" @click="showProd($event)" v-if="(actType == 14)">
          <p class="fw-bolder fs-5">производство азотной кислоты</p>
        </div>
      </div>
    </div>
    <div class="container" v-if="(productionTypeInclArray.includes(prodType) && showThird)">
      <div class="row">
        <div v-for="(pData, index) in productionFilteredData" :key="index" v-bind:dataT='index'
          class="col-md-3 col-xl-2 col-5 activType2 m-3" @click="showNext($event)">
          <p>{{ pData.l }}</p>
        </div>
      </div>
    </div>
    <div class="foot" v-if="(showThird || showSecond)">
      <a @click.prevent="backClick" href="" class="footer_return">&lt; Вернуться назад</a>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import * as yup from "yup";
import { useField, useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { productionData } from "../use/productionData"
import { cherMetData } from '../use/cherMetData.js'
import { colorMetBeginData } from '../use/colorMetBeginData.js'
export default {
  setup() {
    const { handleSubmit, isSubmitting, submitCount } = useForm();
    const store = useStore();
    const router = useRouter();
    const activityText = ref("");
    const productionTypeText = ref("");
    let productionFilteredData = ref([])
    const onChangePT = (e) => {
      if (e.target.options.selectedIndex > -1) {
        productionTypeText.value =
          e.target.options[e.target.options.selectedIndex].innerText;
      }
      production.value = { k: "0", l: "zero" };
    };
    const onChangePTandVP = (e) => {
      if (e.target.options.selectedIndex > -1) {
        activityText.value =
          e.target.options[e.target.options.selectedIndex].innerText;
      }
      production.value = { k: "0", l: "zero" };
      productionType.value = "";
    };
    const {
      value: activityType,
      errorMessage: activityTypeError,
      // handleBlur: yearBlur,
    } = useField(
      "activityType",
      yup
        .string()
        .required("Выберите вид деятельности")
        .min(1, "Выберите вид деятельности"),
      { initialValue: "" }
    );
    const {
      value: productionType,
      errorMessage: productionTypeError,
      // handleBlur: yearBlur,
    } = useField("productionType", yup.string().required("Выберите производство")
      .min(1, "Выберите производство"), { initialValue: "" });
    const productionTypeInclArray = ["29", "31", "38", "44", "60"];
    const {
      value: production,
      // errorMessage: productionError,
      // handleBlur: yearBlur,
    } = useField("production", yup.object(), {
      initialValue: { k: "0", l: "zero" },
    });

    store.commit("setBlackMetProcess", {});
    store.commit("setFirstPageData", {});

    const bData = []
    for (let key in cherMetData) {
      bData.push({
        label: key,
        value: cherMetData[key],
        imgSrc: cherMetData[key].image
      })
    }
    const colorbData = []
    for (let key in colorMetBeginData) {
      colorbData.push({
        label: colorMetBeginData[key].name,
        value: colorMetBeginData[key]
      })
    }
    //проверка мобильного устройства
    // const isMobile = {
    //   Android: function () {
    //     return navigator.userAgent.match(/Android/i);
    //   },
    //   BlackBerry: function () {
    //     return navigator.userAgent.match(/BlackBerry/i);
    //   },
    //   iOS: function () {
    //     return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    //   },
    //   Opera: function () {
    //     return navigator.userAgent.match(/Opera Mini/i);
    //   },
    //   Windows: function () {
    //     return navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i);
    //   },
    //   any: function () {
    //     return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    //   }
    // };
    // if( isMobile.any() ) alert('Mobile');

    const saveData = handleSubmit((values) => {
      store.commit("setActivityType", activityText.value);
      store.commit("setProductionType", productionTypeText.value);
      store.commit("setProduction", production.value);
      store.commit("setCalcType", activityText.value);
      router.push({ path: "/types" });
    });

    watch(activityType, (newValue, oldValue) => {
      production.value = { k: "0", l: "zero" };
    });
    watch(productionType, (newValue, oldValue) => {
      production.value = { k: "0", l: "zero" };
      productionFilteredData.value = productionData.filter(data => data.pT == newValue)
    });
    // watch(techVybross, (newValue, oldValue) => {});
    // productionFilteredData.value = productionData.filter(data => data.pt == productionType.value)

    const showFirst = ref(true)
    const showSecond = ref(false)
    const showThird = ref(false)
    const actType = ref(0)
    const prodType = ref("")
    // const prod = ref({
    //   k: "0", l: "zero"
    // })
    // const prod = ref("")
    if (showFirst) {
      store.commit("setProductionType", "")
    }

    function showProdType(event) {
      actType.value = event.target.tagName == "P" ? event.target.parentNode.getAttribute("data-type") : event.target.getAttribute("data-type")
      store.commit("setActivityType", event.target.tagName == "P" ? event.target.parentNode.getAttribute("data-text") : event.target.getAttribute("data-text"));
      store.commit("setProductionType", "");

      // prod.value = { k: "0", l: "zero" };
      store.commit("setProduction", { k: "0", l: "zero" });
      showFirst.value = false
      showSecond.value = true
    }

    function showColorkMet(event) {
      if (event.target.tagName == "P") {
        store.commit("setProductionType", event.target.parentNode.getAttribute("data-text"));
      } else {
        store.commit("setProductionType", event.target.getAttribute("data-text"));
      }
      store.commit("setColorMetProcess", colorMetBeginData[store.state.productionType])
      router.push({ path: "/metodics" });
    }

    function showBlackMet(event) {
      if (event.target.tagName == "P") {
        store.commit("setProductionType", event.target.parentNode.getAttribute("data-text"));
      } else {
        store.commit("setProductionType", event.target.getAttribute("data-text"));
      }

      // store.commit("setBlackMetProcess", beginData[store.state.productionType])
      // router.push({ path: "/ferrous-metallurgy" });
      router.push({ path: "/metodics" });
    }

    function showProd(event) {
      if (event.target.tagName == "P") {
        store.commit("setProductionType", event.target.parentNode.getAttribute("data-text"));
      } else {
        store.commit("setProductionType", event.target.getAttribute("data-text"));
      }

      prodType.value = event.target.tagName == "P" ? event.target.parentNode.getAttribute("data-type") : event.target.getAttribute("data-type")
      if (productionTypeInclArray.includes(prodType.value)) {
        productionFilteredData.value = productionData.filter(data => data.pT == 1 * (prodType.value))
        showSecond.value = false
        showThird.value = true
      } else {
        showSecond.value = false
        router.push({ path: "/metodics" });
      }
    }
    function showNext(event) {
      store.commit("setProduction", productionFilteredData.value[event.target.tagName == "P" ? event.target.parentNode.getAttribute("dataT") : event.target.getAttribute("dataT")]);
      router.push({ path: "/metodics" });
    }

    function backClick() {
      if (showThird.value) {
        showThird.value = false
        showSecond.value = true
        store.commit("setProductionType", "");
      } else if (showSecond.value) {
        showSecond.value = false
        showFirst.value = true
        store.commit("setActivityType", "");
      }
    }

    return {
      activityType,
      activityTypeError,
      productionType,
      productionTypeError,
      productionTypeInclArray,
      production,
      saveData,
      onChangePT,
      onChangePTandVP,
      productionFilteredData,
      showProdType, showFirst, actType, showSecond, showProd, prodType, showNext, showThird, backClick,
      bData, showBlackMet,
      colorbData, showColorkMet
    };
  },
};
</script>

<style scoped>
.invalid {
  color: red;
  font-size: 12px;
  float: left;
  margin-left: 2rem;
}

.activType {
  transition: .5s;
  min-height: 225px;
  height: auto;
  opacity: 0.9;
  padding: 0 !important;
  /* opacity:0.8; background-color:#000; */
  /* border: 2px solid lightgray; */
  /* border-radius: 10px; */
  /* height:100%;
    position:fixed;
    width:100%;
    top:0;
    left:0; */
}

.activType2 {
  transition: .5s;
  min-height: 100px;
  height: auto;
  border: 2px solid lightgray;
  border-radius: 10px;
}

.activType2 p {
  padding-top: 1rem;
}

.activType2:hover {
  transition: .5s;
  transform: scale(1.1);
  cursor: pointer;
}

.activType:hover {
  transition: .5s;
  transform: scale(1.1);
  cursor: pointer;
}

.activType p {
  /* margin-top: 2rem; */
}

.prodType,
.prodTypeC {
  transition: .5s;
  height: 250px;
  margin-left: 1rem;
  margin-right: 1rem;
  /* border: 2px solid lightgray;
  border-radius: 10px; */
  /* height: 210px; */
}

@media screen and (max-width : 600px) {
  .prodType {}
}

.prodType:hover,
.prodTypeC:hover {
  transition: .5s;
  transform: scale(1.2);
  cursor: pointer;
}

.minerals {
  height: 295px;
}

.foot {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px
}

.footer_return {
  color: #848484;
  font-size: 18px;
  text-decoration: none;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}</style>