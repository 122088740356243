<template>
	<div class="container mb-4">
		<h4>Введите данные</h4>
		<form action="">
			<div class="row">
				<div class="col-lg-8 mb-2">
					<div class="form-floating mb-3">
						<input
							v-model="company"
							id="company"
							name="company"
							@blur="companyBlur"
							type="text"
							class="form-control"
						/>
						<label for="company">Наименование предприятия</label>
						<small class="invalid" v-if="companyError">{{ companyError }}</small>
					</div>
				</div>
				<div class="col-lg-2">
					<div class="form-floating mb-3">
						<input
							v-model="onvCode"
							name="onvCode"
							id="onvCode"
							@blur="onvCodeBlur"
							type="text"
							class="form-control"
						/>
						<label for="floatingInput">Код ОНВ</label>
						<small class="invalid" v-if="onvCodeError">{{ onvCodeError }}</small>
					</div>
				</div>
				<div class="col-lg-2">
					<div class="form-floating mb-3">
						<input
							v-model="year"
							@blur="yearBlur"
							name="year"
							step="1"
							type="number"
							class="form-control"
							id="year"
						/>
						<label for="year">Год</label>
						<small class="invalid" v-if="yearError">{{ yearError }}</small>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							v-model="tonnsByYear"
							@blur="tonnsByYearBlur"
							name="tonnsByYear"
							type="number"
							class="form-control"
							id="tonnsByYear"
						/>
						<label for="tonnsByYear">Общий объем выпускаемой продукции, тонн/год</label>
						<small class="invalid" v-if="tonnsByYearError">{{ tonnsByYearError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							v-model="tonnsUrea"
							@blur="tonnsUreaBlur"
							name="tonnsUrea"
							type="number"
							class="form-control"
							id="tonnsUrea"
						/>
						<label for="tonnsUrea">Масса полученной мочевины, тонн</label>
						<small class="invalid" v-if="tonnsUreaError">{{ tonnsUreaError }}</small>
					</div>
				</div>
			</div>

			<h5>Расход природного газа (заполнить известное)</h5>
			<div class="row">
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="onTechnology"
							name="onTechnology"
							class="form-control"
							id="onTechnology"
						/>
						<label for="onTechnology"
							>Объем потребления природного газа (технология), тыс.м<sup><small>3</small></sup></label
						>
						<small class="invalid" v-if="onTechnologyError">{{ onTechnologyError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input type="number" v-model="onFuel" name="onFuel" class="form-control" id="onFuel" />
						<label for="onFuel"
							>Объем потребления природного газа (топливо), тыс.м<sup><small>3</small></sup></label
						>
						<small class="invalid" v-if="onFuelError">{{ onFuelError }}</small>
					</div>
				</div>

				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input type="number" v-model="onTorch" name="onTorch" class="form-control" id="onTorch" />
						<label for="onTorch"
							>Объем потребления природного газа (на факельную установку), тыс.м<sup
								><small>3</small></sup
							></label
						>
						<small class="invalid" v-if="onTorchError">{{ onTorchError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input type="number" v-model="onBoiler" name="onBoiler" class="form-control" id="onBoiler" />
						<label for="onBoiler"
							>Объем потребления природного газа (на прочие нужды (котел, ТЭЦ, …)), тыс.м<sup
								><small>3</small></sup
							></label
						>
						<small class="invalid" v-if="onBoilerError">{{ onBoilerError }}</small>
					</div>
				</div>
                <input type="hidden" v-model="rashodNo" name="rashodNo" />
				<small class="invalid mb-2" v-if="rashodNoError">{{ rashodNoError }}</small>
				<hr />
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="hidrogenVolume"
							name="hidrogenVolume"
							class="form-control"
							id="hidrogenVolume"
						/>
						<label for="hidrogenVolume"
							>Объем потребления водорода, тыс.м<sup><small>3</small></sup></label
						>
						<small class="invalid" v-if="hidrogenVolumeError">{{ hidrogenVolumeError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="coalVolume"
							name="coalVolume"
							class="form-control"
							id="coalVolume"
						/>
						<label for="coalVolume">Объем потребления угля, ГДж</label>
						<small class="invalid" v-if="coalVolumeError">{{ coalVolumeError }}</small>
					</div>
				</div>

				
				<!-- <div class="col-lg-8">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="underburningRatio" name="underburningRatio" class="form-control"
                            id="underburningRatio">
                        <label for="underburningRatio">Коэффициент недожога углеводородной смеси на факельной
                            установке</label>
                    </div>
                </div> -->
			</div>
			<h5>Состав природного газа, доля в % (заполнить известное)</h5>
			<div class="row">
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="metan"
							name="metan"
							class="form-control"
							id="metan"
							placeholder="name@example.com"
						/>
						<label for="metan">Метан CH<sub>4</sub></label>
						<small class="invalid" v-if="metanError">{{ metanError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="ethane"
							name="ethane"
							class="form-control"
							id="ethane"
							placeholder="name@example.com"
						/>
						<label for="ethane">Этан C<sub>2</sub>H<sub>6</sub></label>
						<small class="invalid" v-if="ethaneError">{{ ethaneError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="propan"
							name="propan"
							class="form-control"
							id="propan"
							placeholder="name@example.com"
						/>
						<label for="propan">Пропан</label>
						<small class="invalid" v-if="propanError">{{ propanError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="uvc4"
							name="uvc4"
							class="form-control"
							id="uvc4"
							placeholder="name@example.com"
						/>
						<label for="uvc4">Углеводороды С<sub>4</sub> (бутан)</label>
						<small class="invalid" v-if="uvc4Error">{{ uvc4Error }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="uvc5"
							name="uvc5"
							class="form-control"
							id="uvc5"
							placeholder="name@example.com"
						/>
						<label for="uvc5">Углеводороды С<sub>5</sub> (пентан)</label>
						<small class="invalid" v-if="uvc5Error">{{ uvc5Error }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="uvc6"
							name="uvc6"
							class="form-control"
							id="uvc6"
							placeholder="name@example.com"
						/>
						<label for="uvc6">Углеводороды С<sub>6</sub> (этилен)</label>
						<small class="invalid" v-if="uvc6Error">{{ uvc6Error }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="carbonDioxide"
							name="carbonDioxide"
							class="form-control"
							id="carbonDioxide"
							placeholder="name@example.com"
						/>
						<label for="carbonDioxide">Углекислый газ</label>
						<small class="invalid" v-if="carbonDioxideError">{{ carbonDioxideError }}</small>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-floating mb-3">
						<input
							type="number"
							v-model="nonCarbonSum"
							name="nonCarbonSum"
							class="form-control"
							id="nonCarbonSum"
							placeholder="name@example.com"
						/>
						<label for="nonCarbonSum">водород (как компонент трубопроводного газа, подмес до 10%)</label>
						<small class="invalid" v-if="nonCarbonSumError">{{ nonCarbonSumError }}</small>
					</div>
				</div>
				<input type="hidden" v-model="sostavNo" name="sostavNo" />
				<small class="invalid mb-2" v-if="sostavNoError">{{ sostavNoError }}</small>
			</div>
			<!-- <div class="row">
                <div class="col-lg-8">
                    <div class="form-floating mb-3">
                        <input type="number" v-model="emissionCH4" name="emissionCH4" class="form-control" id="emissionCH4"
                            placeholder="name@example.com">
                        <label for="emissionCH4">Выбросы CH<sub>4</sub>, т в год</label>
                        <small class="invalid" v-if="emissionCH4Error">{{ emissionCH4Error }}</small>
                    </div>
                </div>
            </div> -->
			<button @click.prevent="calculate" class="btn btn-primary btn-lg">Рассчитать</button>
		</form>
		<!-- модальное окошко -->
		<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-xl modal-dialog-centered modal-fullscreen-md-down">
				<div class="modal-content">
					<div class="modal-header">
						<h1 class="modal-title fs-5" id="exampleModalLabel">Результаты расчетов</h1>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<table class="table table-bordered table-hover table-dark">
							<thead>
								<tr class="table-active">
									<th>Наименование</th>
									<th>Значение</th>
								</tr>
							</thead>
							<tbody>
								<tr class="table-info">
									<td>Наименование предприятия</td>
									<td>{{ company }}</td>
								</tr>
								<tr class="table-info">
									<td>код ОНВ</td>
									<td>{{ onvCode }}</td>
								</tr>
								<tr class="table-success">
									<td>Объем выбросов ПГ, т. СО<sub>2</sub></td>
									<td>{{ result.directEmission.toFixed(2) }}</td>
								</tr>
								<tr class="table-success">
									<td>Улавливаемые выбросы СО<sub>2</sub>, т. СО<sub>2</sub> (в т.ч. на получение мочевины)</td>
									<td>{{ result.capturedEmission.toFixed(2) }}</td>
								</tr>
								<tr class="table-success">
									<td>Скорректированный объем выбросов ПГ с учетом улавливания, т. СО<sub>2</sub></td>
									<td>{{ result.adjustedEmission.toFixed(2) }}</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Закрыть</button>
						<!-- <button type="button" class="btn btn-primary">Save changes</button> -->
					</div>
				</div>
			</div>
		</div>
		<!-- модальное окошко -->
	</div>
</template>

<script>
import * as yup from "yup"
import { useField, useForm } from "vee-validate"
import { watch, ref, reactive, onMounted } from "vue"
import { Modal } from "bootstrap"

export default {
	setup() {
		const mod_state = reactive({
			modal_demo: null,
		})

		onMounted(() => {
			mod_state.modal_demo = new Modal("#exampleModal", {})
		})

		function openModal() {
			mod_state.modal_demo.show()
		}

		function closeModal() {
			mod_state.modal_demo.hide()
		}

		const { handleSubmit, isSubmitting, submitCount } = useForm()
		const FOR_METAN = 1
		const FOR_ETHANE = 2
		const FOR_PROPAN = 3
		const FOR_UVC4 = 4
		const FOR_UVC5 = 5
		const FOR_UVC6 = 6
		const CARBON_DIOXIDE = 1

		const DENSITY_CO2 = 1.9768
		const DENSITY_CH4 = 0.717
		const GWP_CP4 = 25

		const showModal = ref(false)

		const { value: rashodNo, errorMessage: rashodNoError } = useField(
			"rashodNo",
			yup
				.number()
				.moreThan(0, `Отсутствует расход природного газа`)
				.typeError("Отсутствует расход природного газа"),
			{ initialValue: 0 }
		)
		const { value: sostavNo, errorMessage: sostavNoError } = useField(
			"sostavNo",
			yup.number().moreThan(0, `Отсутствует состав природного газа`).max(100, `Не может быть больше 100%`),
			{ initialValue: 0 }
		)

		const {
			value: company,
			errorMessage: companyError,
			handleBlur: companyBlur,
		} = useField(
			"company",
			yup
				.string()
				.trim()
				.required("Введите наименование компании")
				.min(3, `Наименование компании не должно быть меньше 3 символов`)
		)
		const {
			value: onvCode,
			errorMessage: onvCodeError,
			handleBlur: onvCodeBlur,
		} = useField(
			"onvCode",
			yup.string().trim().required("Введите код ОНВ").min(5, `Код ОНВ не должен быть меньше меньше 5 символов`)
		)
		const {
			value: tonnsByYear,
			errorMessage: tonnsByYearError,
			handleBlur: tonnsByYearBlur,
		} = useField(
			"tonnsByYear",
			yup.number().typeError("Введите число").moreThan(0, "Значение должно быть больше 0"),
			{ initialValue: 0 }
		)
		const {
			value: tonnsUrea,
			errorMessage: tonnsUreaError,
			handleBlur: tonnsUreaBlur,
		} = useField(
			"tonnsUrea",
			yup.number().typeError("Введите число").moreThan(0, "Значение должно быть больше 0"),
			{ initialValue: 0 }
		)
		const {
			value: year,
			errorMessage: yearError,
			handleBlur: yearBlur,
		} = useField(
			"year",
			yup
				.string()
				.trim()
				.required("Введите год")
				.min(4, "Введите корректный год")
				.max(4, "Введите корректный год")
		)
		const {
			value: onFuel,
			errorMessage: onFuelError,
			handleBlur: onFuelBlur,
		} = useField(
			"onFuel",
			yup
				.number()
				// .transform((value) => Number.isNaN(value) ? 0 : value )
				.typeError("Должно быть число")
				// .default(0)
				.min(0, `Не может быть меньше 0`),
			{ initialValue: 0 }
		)
		const {
			value: onTechnology,
			errorMessage: onTechnologyError,
			handleBlur: onTechnologyBlur,
		} = useField(
			"onTechnology",
			yup
				.number()
				// .transform((value) => Number.isNaN(value) ? 0 : value )
				.typeError("Должно быть число")
				.min(0, `Не может быть меньше 0`),
			{ initialValue: 0 }
		)
		const {
			value: onTorch,
			errorMessage: onTorchError,
			handleBlur: onTorchBlur,
		} = useField(
			"onTorch",
			yup
				.number()
				// .transform((value) => Number.isNaN(value) ? 0 : value )
				// .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value)
				.typeError("Должно быть число")
				// .default(0)
				.min(0, `Не может быть меньше 0`),
			{ initialValue: 0 }
		)
		const {
			value: onBoiler,
			errorMessage: onBoilerError,
			handleBlur: onBoilerBlur,
		} = useField(
			"onBoiler",
			yup
				.number()
				// .transform((value) => Number.isNaN(value) ? 0 : value )
				.typeError("Должно быть число")
				// .default(0)
				.min(0, `Не может быть меньше 0`),
			{ initialValue: 0 }
		)
		const { value: hidrogenVolume, errorMessage: hidrogenVolumeError } = useField("hidrogenVolume", yup.number(), {
			initialValue: 0,
		})
		const { value: coalVolume, errorMessage: coalVolumeError } = useField("coalVolume", yup.number(), {
			initialValue: 0,
		})
		const { value: underburningRatio } = useField("underburningRatio", yup.number(), { initialValue: 0.005 })
		const { value: metan, errorMessage: metanError } = useField(
			"metan",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const { value: ethane, errorMessage: ethaneError } = useField(
			"ethane",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const { value: propan, errorMessage: propanError } = useField(
			"propan",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const { value: uvc4, errorMessage: uvc4Error } = useField(
			"uvc4",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const { value: uvc5, errorMessage: uvc5Error } = useField(
			"uvc5",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const { value: uvc6, errorMessage: uvc6Error } = useField(
			"uvc6",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const { value: carbonDioxide, errorMessage: carbonDioxideError } = useField(
			"carbonDioxide",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const { value: nonCarbonSum, errorMessage: nonCarbonSumError } = useField(
			"nonCarbonSum",
			yup.number().min(0, "Значение не может быть меньше 0").max(100, "Значение не может быть больше 100"),
			{ initialValue: 0 }
		)
		const {
			value: emissionCH4,
			errorMessage: emissionCH4Error,
			handleBlur: emissionCH4Blur,
		} = useField("emissionCH4", yup.number().typeError("Введите число").min(0, "Значение не может быть меньше 0"), {
			initialValue: 0,
		})
		let gas = reactive({
			metan: metan,
			ethane: ethane,
			propan: propan,
			uvc4: uvc4,
			uvc5: uvc5,
			uvc6: uvc6,
			carbonDioxide: carbonDioxide,
			nonCarbonSum: nonCarbonSum,
			onFuel: onFuel,
			onTechnology: onTechnology,
            coalVolume: coalVolume,
            hidrogenVolume: hidrogenVolume,
			onTorch: onTorch,
			onBoiler: onBoiler,
			underburningRatio: underburningRatio,
			emissionCH4: emissionCH4,
		})
		const nts = {
			metan: 35.88,
			ethane: 64.36,
			propan: 93.18,
			uvc4: 123.57,
			uvc5: 156.63,
			uvc6: 60.1,
			carbonDioxide: 12.64,
			nonCarbonSum: 108,
		}
        let result = reactive({
			sumCO2tech: 0,
			sumCO2fuel: 0,
			sumCO2coal: 0,
            summCO2gas: 0,
            sumCO2hidrogen: 0,
            summCO2torch: 0,
            sumCO2gasTorch: 0,
            sumCO2gasOther: 0,
            directEmission: 0,
            capturedEmission: 0,
            adjustedEmission: 0
		})
		const br = 36.4134914183
		const tr = ref(0)
		watch(
			[gas, tonnsUrea], ([], []) => {
				tr.value =
					(gas.metan * nts.metan +
						gas.ethane * nts.ethane +
						gas.propan * nts.propan +
						gas.uvc4 * nts.uvc4 +
						gas.uvc5 * nts.uvc5 +
						gas.uvc6 * nts.uvc6 +
						gas.carbonDioxide * nts.carbonDioxide +
						gas.nonCarbonSum * nts.nonCarbonSum) /
					100
                result.sumCO2tech = onTechnology.value * 1.129 * 1.59 * tr.value / br
                result.sumCO2coal = coalVolume.value * 94.6 / 1000
                result.summCO2gas = onFuel.value * 1.129 * 1.59 * tr.value / br
                result.sumCO2hidrogen = hidrogenVolume.value * 1.129 * 1.59 * tr.value / br
                result.sumCO2fuel = result.sumCO2coal + result.summCO2gas + result.sumCO2hidrogen
                result.sumCO2gasTorch = onTorch.value * 1.129 * 1.59 * tr.value / br
                result.sumCO2gasOther = onBoiler.value * 1.129 * 1.59 * tr.value / br
                result.summCO2torch = result.sumCO2gasTorch + result.sumCO2gasOther
                result.directEmission = result.sumCO2tech + result.sumCO2fuel + result.summCO2torch
                result.capturedEmission = tonnsUrea.value * 0.733
                result.adjustedEmission = result.directEmission - result.capturedEmission
                rashodNo.value = gas.onFuel + gas.onTechnology + gas.onTorch + gas.onBoiler
                sostavNo.value =
				gas.metan +
				gas.ethane +
				gas.propan +
				gas.uvc4 +
				gas.uvc5 +
				gas.uvc6 +
				gas.carbonDioxide +
				gas.nonCarbonSum
			},
			{ deep: true }
		)


		const calculate = handleSubmit((values) => {
			showModal.value = true
			openModal()
		})

		return {
			calculate,
			company,
			companyError,
			companyBlur,
			onvCode,
			onvCodeError,
			onvCodeBlur,
			tonnsByYear,
			tonnsByYearError,
			tonnsByYearBlur,
			tonnsUrea,
			tonnsUreaError,
			tonnsUreaBlur,
			year,
			yearError,
			yearBlur,
			onFuel,
			onFuelError,
			onFuelBlur,
			onTechnology,
			onTechnologyError,
			onTechnologyBlur,
			onTorch,
			onTorchError,
			onTorchBlur,
			onBoiler,
			onBoilerError,
			onBoilerBlur,
			underburningRatio,
			hidrogenVolume,
			hidrogenVolumeError,
			coalVolume,
			coalVolumeError,
			metan,
			metanError,
			ethane,
			ethaneError,
			propan,
			propanError,
			uvc4,
			uvc4Error,
			uvc5,
			uvc5Error,
			uvc6,
			uvc6Error,
			carbonDioxide,
			carbonDioxideError,
			nonCarbonSum,
			nonCarbonSumError,
			emissionCH4,
			emissionCH4Error,
			emissionCH4Blur,
			showModal,
			result,
			rashodNo,
			rashodNoError,
			sostavNo,
			sostavNoError,
		}
	},
}
</script>

<style scoped>
.invalid {
	color: red;
	font-size: 12px;
	float: left;
	margin-left: 0.5rem;
}
</style>
